// ErrorModal.tsx
import React from "react";
import { ErrorModalProps } from "./types";
import { ErrorModalWrapper } from "./styled/errorModal"; // Adjust the import path as needed

const ErrorModal: React.FC<ErrorModalProps> = ({
  open,
  title,
  message,
  onClose,
}) => {
  // Assuming you have logic to control the visibility using `open`
  if (!open) return null;

  return (
    <React.Fragment>
      <div className="backdrop" />{" "}
      {/* Ensure you have a backdrop style */}
      <ErrorModalWrapper>
        <h2>{title || "An Error Occurred!"}</h2>
        <p>{message}</p>
        <div className="error-modal__actions">
          <button type="button" onClick={onClose}>
            Okay
          </button>
        </div>
      </ErrorModalWrapper>
    </React.Fragment>
  );
};

export default ErrorModal;

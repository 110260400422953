// appMenu.ts
import { styled } from "@mui/material/styles";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";

export const MenuDivider = styled(Divider)(({ theme }) => ({
  // styles...
}));

export const NestedMenuItem = styled(ListItemButton)(({ theme }) => ({
  // styles...
}));

export const SelectedMenuItem = styled(ListItemButton)<{
  isSelected?: boolean;
}>(({ theme, isSelected }) => ({
  transition: "box-shadow 1s",
  boxShadow: isSelected
    ? `0 0 3px ${theme.palette.primary.main}, 0 0 9px ${theme.palette.primary.main}, 0 0 11px ${theme.palette.primary.main}, 0 0 30px ${theme.palette.primary.main}`
    : "none",
  // Other styles...
}));

import React, { FC, ReactElement } from "react";
import { IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AppMenu from "./appMenu";
import { CustomDrawer, ToolbarSpacer } from "./styled/navigationSideBar";

interface Props {
  open: boolean;
  handleMenuClose: () => void;
}

const NavigationSideBar: FC<Props> = ({
  open,
  handleMenuClose,
}): ReactElement => {
  return (
    <CustomDrawer variant="permanent" open={open}>
      <ToolbarSpacer>
        <IconButton onClick={handleMenuClose}>
          <ChevronLeftIcon htmlColor="#fff" />
        </IconButton>
      </ToolbarSpacer>
      <AppMenu />
    </CustomDrawer>
  );
};

export default NavigationSideBar;

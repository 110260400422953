// PageTitle.tsx
import React, { FC, ReactElement } from "react";
import { FieldProTitle } from "./styled/pageTitle"; // Ensure this path matches your file structure

const PageTitle: FC<{ title: String }> = ({ title }): ReactElement => {
  return (
    <FieldProTitle variant="h4" color="textSecondary">
      {title}
    </FieldProTitle>
  );
};

export default PageTitle;

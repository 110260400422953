import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadProjectsAsync } from "./projectModuleSlice";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Button as StyledButton,
  CustomTableCell,
  ProjectInfoListRoot,
  ProjectTableCell,
} from "./styled"; // Update this path to your styled Button
import Toast from "../ui/toast";
import ErrorModal from "../ui/errorModal";
import ConfirmModal from "../ui/ConfirmModal";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import BasePage from "../ui/basePage";
import { IProjectInfo} from "./types";
import { AppDispatch, RootState } from "../../appStore";

const ProjectInfoList = () => {
  const [selectedId, setSelectedId] = useState<number | null>(null);
  //vars of tracking state we should move to creating s full slice
    // Assuming handling of notification and modal state
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState("");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

   const { projectList } = useSelector(
    (state: RootState) => state.projectModule
  );

  useEffect(() => {
    console.log("loadProjectsAsync");
    const dataPath = `${process.env.PUBLIC_URL || ""}/data/projects.json`;
    console.log(`loadProjectsAsync loading ${dataPath}`); //log path
     dispatch(loadProjectsAsync(dataPath)); // Adjust the server path as needed
    }, [dispatch]);

  const handleDeleteProjectInfo = (id: number) => {
    setIsConfirmModalOpen(true);
    setSelectedId(id);
  };

  const confirmDelete = (confirm: boolean) => {
    if (confirm && selectedId !== null) {
      // Perform the delete action here
      console.log(`Project with ID ${selectedId} deleted`);
      setToastMessage("Project deleted successfully");
      setIsToastOpen(true);
    }
    setIsConfirmModalOpen(false);
  };

  return (
    <ProjectInfoListRoot>
      <StyledButton
        variant="contained"
        size="small"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => console.log("Add new project")}
      >
        Add
      </StyledButton>
      <Table>
        <TableHead>
          <TableRow>
            <ProjectTableCell>SrNo</ProjectTableCell>
            <ProjectTableCell>Project Name</ProjectTableCell>
            <ProjectTableCell>Subject</ProjectTableCell>
            <ProjectTableCell>Date</ProjectTableCell>
            <ProjectTableCell>Type</ProjectTableCell>
            <ProjectTableCell>Edit</ProjectTableCell>
            <ProjectTableCell>Delete</ProjectTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(projectList) ? (
            projectList.map((projectInfo: IProjectInfo) => (
              <TableRow key={projectInfo.SrNo}>
                <CustomTableCell>{projectInfo.SrNo}</CustomTableCell>
                <CustomTableCell>{projectInfo.ProjectName}</CustomTableCell>
                <CustomTableCell>{projectInfo.Subject}</CustomTableCell>
                <CustomTableCell>{projectInfo.Date}</CustomTableCell>
                <CustomTableCell>{projectInfo.Type}</CustomTableCell>
                <CustomTableCell>
                  <StyledButton
                    variant="contained"
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={() =>
                      console.log(`Edit project ID: ${projectInfo.SrNo}`)
                    }
                  >
                    Edit
                  </StyledButton>
                </CustomTableCell>
                <CustomTableCell>
                  <StyledButton
                    variant="contained"
                    size="small"
                    color="secondary"
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDeleteProjectInfo(projectInfo.SrNo)}
                  >
                    Delete
                  </StyledButton>
                </CustomTableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={7}>No projects found</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {isConfirmModalOpen && (
        <ConfirmModal
          open={isConfirmModalOpen}
          title="Confirm Delete"
          message={`Are you sure you want to delete project ${selectedId}?`}
          onClose={() => setIsConfirmModalOpen(false)}
          onConfirm={() => confirmDelete(true)}
          onReject={() => confirmDelete(false)}
        />
      )}
      {isToastOpen && (
        <Toast open={isToastOpen} message={toastMessage} status="Info" />
      )}
      {isErrorModalOpen && (
        <ErrorModal
          open={isErrorModalOpen}
          title="Error in Project List"
          message={errorModalMessage ?? null}
          onClose={() => setIsErrorModalOpen(false)}
        />
      )}
    </ProjectInfoListRoot>
  );
};

export default ProjectInfoList;

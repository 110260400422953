import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectModelState, IProjectInfo,StorageTypes } from './types'; // Adjust the path to your types
import { fetchList } from "./projectModuleAPI";
import { RootState } from '../../appStore';
const initialState: ProjectModelState = {
  storageTypes: StorageTypes.json, // Adjust as necessary
  storagePathLocation: "", // Adjust as necessary
  projectList: [],
  loading: false,
  error: null,
};

export const loadProjectsAsync = createAsyncThunk<
  IProjectInfo[],
  string,
  { rejectValue: string }
>("projects/loadProjects", async (serverPath, { rejectWithValue }) => {
  try {
    console.log("loadProjectsAsync preFetching");
    const response: any = await fetchList(serverPath);
    const projectInfoList = response.projectList;
    console.log(
      "projectList type:",
      typeof projectInfoList,
      Array.isArray(projectInfoList)
    );

    return projectInfoList; // This will be used as the payload in the fulfilled action
  } catch (error) {
    console.log("loadProjectsAsync Error");
    // Here, you're correctly using rejectWithValue to handle errors.
    // This will be used as the payload in the rejected action.
    if (error instanceof Error) return rejectWithValue(error.message);
    return rejectWithValue("An unknown error occurred");
  }
});

export const projectModuleSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    addProject: (state, action: PayloadAction<IProjectInfo>) => {
      state.projectList.push(action.payload);
    },
    deleteProject: (state, action: PayloadAction<number>) => {
      state.projectList = state.projectList.filter(
        (project) => project.SrNo !== action.payload
      );
    },
    viewFile: (state, action: PayloadAction<IProjectInfo>) => {
      // Depending on the behavior, you might want to handle viewing differently than adding to the list
      // For now, let's just add it to the list
      state.projectList.push(action.payload);
    },
    sortList: (state, action: PayloadAction<string>) => {
      // Implement sorting logic here
      // action.payload could be "ASC" or "DESC"
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadProjectsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadProjectsAsync.fulfilled, (state, action) => {
        state.loading = false;
        console.log("projectList type:", action.payload);

        //const projectList = action.payload;
        console.log(
          "projectList type:",
          typeof action.payload,
          Array.isArray(action.payload)
        );

        state.projectList = action.payload;
      })
      .addCase(loadProjectsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? "Failed to load projects";
        // Ensure you're not accidentally modifying `projectList` here
      })
  },
});

export const { addProject, deleteProject, viewFile, sortList } = projectModuleSlice.actions;

export const selectProject = (state: RootState) => state.projectModule;

export default projectModuleSlice.reducer;

// ./styled/home.ts (Refactoring within this file for the AboutUsPage)

import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import MUIButton from "@mui/material/Button";
// Assuming the constants might be used for additional styling
import {
  FLEX_GROW,
  spacingUnit
} from "../../../helpers/constants";
//
export const ProjectInfoListRoot = styled("div")(({ theme }) => ({
  marginLeft: theme.spacing(4), // Adjust this value as needed for the left margin
  // You can add additional styling here as needed
}));
// Container styled component
export const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
}));

// TextField styled component
export const TextField = styled("input")(({ theme }) => ({
  marginLeft: theme.spacing(spacingUnit), // Updated to use spacing API of MUI v5
  marginRight: theme.spacing(spacingUnit),
  width: 250,
}));

// Button styled component
export const Button = styled(MUIButton)(({ theme }) => ({
  margin: theme.spacing(spacingUnit), // Updated to use spacing API of MUI v5
}));

// Refactored ProjectTableCell using styled API
export const ProjectTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

// Existing ProjectsLayout component
export const ProjectsLayout = styled("div")(({ theme }) => ({
  flex: FLEX_GROW,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}));

// CustomTableCell styled component
export const CustomTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export interface IProjectInfo {
    SrNo: number;
    ProjectName: string;
    Subject: string;
    Date: string;
    Type: string;
}

export interface IProjectDetailFormProps {
    handleSubmit: React.MouseEventHandler<HTMLButtonElement>;
    submitting: React.MouseEventHandler<HTMLButtonElement>;
    onCancel: React.MouseEventHandler<HTMLButtonElement>;
}
export enum StorageTypes {
    json = "json",
    s3 = "s3",
    azblob = "azblob",
    sqldb = "sqldb",
}

//move to core Models file and import from there
export interface ProjectModelState {
    storageTypes: StorageTypes;
    storagePathLocation: string;
    projectList: IProjectInfo[];
    loading: boolean;
    error: string | null;
}

// Assuming your types file is named 'types.ts'
import {
  AuthLogInReq,
  FB_AuthLoginResponse,
  FB_AuthSignUpResponse,
} from "./types";
  
import { FIREBASE_APIKEY, BASE_URL } from "../../helpers/constants";



export const signInWithPassword = async ({
  email,
  secret,
}: AuthLogInReq): Promise<FB_AuthLoginResponse> => {
  const response = await fetch(
    `${BASE_URL}:signInWithPassword?key=${FIREBASE_APIKEY}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email,
        password: secret,
        returnSecureToken: true,
      }),
    }
  );

  if (!response.ok) {
    throw new Error("Authentication failed");
  }

  return response.json();
};

export const signUp = async ({
  email,
  secret,
}: AuthLogInReq): Promise<FB_AuthSignUpResponse> => {
  const response = await fetch(`${BASE_URL}:signUp?key=${FIREBASE_APIKEY}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password: secret, returnSecureToken: true }),
  });

  if (!response.ok) {
    throw new Error("Sign up failed");
  }

  return response.json();
};

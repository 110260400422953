// Header.tsx
import React, { FC, ReactElement } from "react";
import { Typography, IconButton, Tooltip, AppBar, Toolbar, CssBaseline } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness3Icon from "@mui/icons-material/Brightness3";
import UserIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/LockOutlined";
import { TopAppBar, TopToolbar, MenuButton, TitleContainer, ThemeSwitchButton, AuthButton } from "./styled/header";
import { useAppSelector, useAppDispatch } from '../../hooks/reduxHooks';
import { selectAuth } from '../auth/authSlice';
import { APP_TITLE } from "../../helpers/constants";

interface Props {
  open: boolean;
  handleMenuOpen: () => void;
  handleSignUp: () => void;
  handleSignIn: () => void;
  handleDisconnect: () => void;
  toggleTheme: () => void;
  useDefaultTheme: boolean;
}

const Header: FC<Props> = ({
  open,
  handleMenuOpen,
  handleSignUp,
  handleSignIn,
  handleDisconnect,
  toggleTheme,
  useDefaultTheme,
}): ReactElement => {
  const authState = useAppSelector(selectAuth);
  const isLoggedIn = authState.isLoggedIn;

  return (
    <>
      <CssBaseline />
      <TopAppBar open={open} position="fixed" elevation={0}>
        <TopToolbar>
          <MenuButton
            color="inherit"
            aria-label="open menu"
            onClick={handleMenuOpen}
            edge="start"
            open={open}
            size="small"
          >
            <MenuIcon />
          </MenuButton>
          <TitleContainer variant="h6" noWrap>
            {APP_TITLE}
          </TitleContainer>
          <ThemeSwitchButton onClick={toggleTheme}>
            <Tooltip title={useDefaultTheme ? "Switch to dark mode" : "Switch to light mode"} placement="bottom">
              {useDefaultTheme ? <Brightness3Icon /> : <Brightness7Icon />}
            </Tooltip>
          </ThemeSwitchButton>
          {!isLoggedIn && (
            <AuthButton size="small" onClick={handleSignIn} color="inherit">
              <UserIcon />
            </AuthButton>
          )}
          {isLoggedIn && (
            <AuthButton size="small" onClick={handleDisconnect} color="inherit">
              <LogoutIcon />
            </AuthButton>
          )}
        </TopToolbar>
      </TopAppBar>
    </>
  );
};

export default Header;

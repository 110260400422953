import {configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useDispatch as rawUseDispatch,
  useSelector as rawUseSelector,
} from "react-redux";
import streamReducer from "../components/stream/streamSlice";
import projectModuleSlice from "../components/projects/projectModuleSlice";
import authReducer from '../components/auth/authSlice';
import uiReducer from "../components/ui/uiSlice";


//Here we wire up our different State Reducers from thier Slices
//TODO:change the projectModule Slice to be Driver Profile Slice
export const store = configureStore({
  reducer: {
    projectModule: projectModuleSlice,
    streams: streamReducer,
    auth: authReducer,
    ui: uiReducer,
  },
});

// Type for the Redux store dispatch function
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
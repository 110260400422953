// styled/UserProfileStyles.ts
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import {
  PAGE_WIDTH,
} from "../../../../helpers/constants";
export const UserProfileCard = styled(Card)(({ theme }) => ({
  margin: theme.spacing(4),
  width: PAGE_WIDTH,
  maxWidth: `25rem`,
  borderRadius: `6px`,
  boxShadow: `0 0 3px ${theme.palette.primary.main}, 0 0 9px ${theme.palette.primary.main}, 0 0 11px ${theme.palette.primary.main}, 0 0 30px ${theme.palette.primary.main}`,
  padding: theme.spacing(1),
  textAlign: `left`,
}));

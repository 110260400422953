import { useRef, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../hooks/reduxHooks";
import { selectAuth } from "../auth/authSlice";
import {
  ProfileFormRoot,
  ProfileFormControl,
  ProfileFormLabel,
  ProfileFormInput,
  ProfileFormActions,
  ProfileFormToggle,
} from "./styled/profileForm"; // Adjust the import path as necessary

const ProfileForm: React.FC = (props) => {
  const authState = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  const passwordInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!authState.isAuthLoading && !authState.isLoggedIn) {
      navigate("/");
    }
  }, [authState, navigate]);

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    let enteredPassword = passwordInputRef.current
      ? passwordInputRef.current.value
      : "";

    console.log(`Your Password has been changed`);
  };

  return (
    <ProfileFormRoot onSubmit={submitHandler}>
      <ProfileFormControl>
        <ProfileFormLabel htmlFor="new-password">New Password</ProfileFormLabel>
        <ProfileFormInput
          ref={passwordInputRef}
          type="password"
          id="new-password"
        />
      </ProfileFormControl>
      <ProfileFormActions>
        <ProfileFormToggle type="submit" variant="contained" color="primary">
          Change Password
        </ProfileFormToggle>
      </ProfileFormActions>
    </ProfileFormRoot>
  );
};

export default ProfileForm;

// styled/SettingsStyles.ts
import { styled } from "@mui/material/styles";

// Define a styled component for the Settings page's root div
export const SettingsRoot = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  // You can use ERRORWIN_TOP, ERRORWIN_LEFT, ERRORWIN_WIDTH if needed for additional styling
}));

//Make this a json file 
// APP TEXT
export const APP_TITLE = "Dabbed Out Racing";
export const FOOTER_TEXT = `${new Date().getFullYear()} @Skylynx Network`;
export const APP_LOGO_PATH = "../webAssets/HomeImage.jpg";
export const APP_LOGO_WIDTH = 100;
export const FLEX_GROW= 1;
export const BORDER_STYLE_MAIN = "1px solid #ccc";
//Spacing
export const spacingUnitlarge = 4;
export const spacingUnit = 1;
export const spacingUnitSmall = 0.25;
export const MARGIN_BOTTOM = 20;
export const MARGIN_LEFT = 15;
export const MARGIN_TOP = 50;
export const MARGIN_RIGHT = 15;
export const PADDING_BOTTOM = 15;
export const PADDING_LEFT = 30;
export const PADDING_TOP = 15;
export const PADDING_RIGHT = 15;
export const fontSizeXL = 24;
export const fontSizeL = 15;
export const fontSizeM = 13;
export const fontSizeS = 10;
export const fontSizeXS = 8;
export const fontWeightHeavy = 300;
export const fontWeightLight = 150;

// PAGES TITLE
export const PAGE_TITLE_HOME = "Home";
export const PAGE_TITLE_AUTH = "Login/Signup";
export const PAGE_TITLE_AUTH_SIGNUP = "Signup";
export const PAGE_TITLE_AUTH_LOGIN = "Login";
export const PAGE_TITLE_DASHBOARD = "Streaming";
export const PAGE_TITLE_ACCOUNTS = "Users";
export const SUBPAGE_TITLE = "Most Viewed";
export const PAGE_TITLE_SETTINGS = "Settings";
// UI CONSTANTS
export const Sketch_HEIGHT = 300;
export const Sketch_WIDTH = 500;
export const FOOTER_HEIGHT = 30;
export const HEADER_HEIGHT = 60;
export const DRAWER_WIDTH = 250;
export const CARD_WIDTH = 350;
export const MEDIA_HEIGHT = 340;
export const MEDIA_HEIGHT_px = "340px";
export const MEDIA_WIDTH = 350;
export const PAGE_WIDTH = 500;
//Error Window CONSTANTS
export const ERRORWIN_TOP = `30vh`;
export const ERRORWIN_LEFT = `15rem`;
export const ERRORWIN_WIDTH = `30rem`;
    
// move to .env
//Auth Provider and Database keys comes from .env
export const FIREBASE_APIKEY = "AIzaSyCp8_oWSEyeUKp7rSVo6pV65A-QWCBCjCk";
export const BASE_URL = `https://identitytoolkit.googleapis.com/v1/accounts`;
export const app_URL = `https://sky.firebaseio.com/`;
import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../hooks/reduxHooks"; // Adjust path as necessary
import { RootState } from "../../appStore"; // Your store file
import { fetchStreams } from "../stream/twitchThunk";
import StreamCarousel from "../stream/StreamCarousel";

const StreamsComponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const streams = useAppSelector((state: RootState) => state.streams.items);
  const status = useAppSelector((state: RootState) => state.streams.status);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchStreams());
    }
  }, [status, dispatch]);

  return (
    <div>
      {status === "loading" && <p>Loading...</p>}
      {status === "succeeded" && (
        <ul>
          {streams.map((stream) => (
            <li key={stream.id}>{stream.user_name}</li>
          ))}
        </ul>
      )}
      {status === "failed" && <p>Error fetching streams.</p>}
    </div>
  );
};

export default StreamsComponent;

import React, {
  Fragment,
  ReactElement,
  useEffect,
  useReducer,
  FC,
} from "react";
import { useAppSelector, useAppDispatch } from "./hooks/reduxHooks";
import { ErrorBoundaryMode } from "./components/ui/types";
import {
  createTheme,
  Theme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import { Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { selectUI } from "./components/ui/uiSlice";

import { authActions, selectAuth } from "./components/auth/authSlice";
import Toast from "./components/ui/toast";
import ErrorBoundary from "./components/ui/ErrorBoundary";
// components
import Layout from "./components/ui/layout";

// theme
import { lightTheme, darkTheme } from "./theme/appTheme";

// app routes
import { Loadroutes } from "./config";

// constants
import { APP_TITLE } from "./helpers/constants";

// interfaces
import RouteItem from "./config/RouteItem.model";
import { ToastItemProps } from "./components/ui/types";
import useMediaQuery from "@mui/material/useMediaQuery";

//define timer for user log in
// define app context
const AppContext = React.createContext(null);

// default component
const DefaultComponent: FC<{}> = (): ReactElement => (
  <div>{`No Component Defined.`}</div>
);

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [useDefaultTheme, toggle] = useReducer((theme) => !theme, true);
  //tracks first time load on a machine
  let isInitial = true;
  const uiState = useAppSelector(selectUI);
  const authState = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  let logoutTimer: NodeJS.Timeout;
  // define custom theme
  let theme: Theme = createTheme(useDefaultTheme ? lightTheme : darkTheme);
  theme = responsiveFontSizes(theme);
  let routes = Loadroutes();
  const createTimer = (remainingTime: number) => {
    return setTimeout(() => dispatch(authActions.logout()), remainingTime);
  };
  //This controls the auth state of the user
  useEffect(() => {
    console.log(`$App loading`);
    if (isInitial) {
      isInitial = false;
      if (authState.isLoggedIn) {
        logoutTimer = createTimer(authState.remainingTime);
      }
    }
    if (authState.isLoggedIn) {
      //Bug with getting remainingTime from storage location
      createTimer(authState.remainingTime);
    }
    //Setup timer for login

    if (authState.isLoggedIn === false && logoutTimer !== undefined) {
      //Clear logout timer when users logs out
      return clearTimeout(logoutTimer);
    }
    //dispatch(sendCartData(cart));
  }, [uiState, authState, useAppDispatch, isInitial]);

  let toastMessageState: ToastItemProps = {
    open: uiState.notification.open,
    message: uiState.notification.message,
    status: uiState.notification.status,
  };

  return (
    <>
      <ErrorBoundary mode={ErrorBoundaryMode.Debug}>
        <AppContext.Provider value={null}>
          <Helmet>
            <title>{APP_TITLE}</title>
          </Helmet>
          <ThemeProvider theme={theme}>
            <Fragment>
              {uiState.notification &&
                uiState.notification.open && (
                  <Toast
                    open={uiState.notification.open}
                    message={uiState.notification.message}
                    status={uiState.notification.status}
                  />
                )}
              <Routes>
                {routes.map((route: RouteItem) =>
                  route.subRoutes ? (
                    route.subRoutes.map((subRoute: RouteItem) => (
                      <Route
                        key={subRoute.key}
                        path={subRoute.path}
                        element={
                          <Layout
                            toggleTheme={toggle}
                            useDefaultTheme={useDefaultTheme}
                          >
                            {subRoute.component ? (
                              <subRoute.component />
                            ) : (
                              <DefaultComponent />
                            )}
                          </Layout>
                        }
                      />
                    ))
                  ) : (
                    <Route
                      key={route.key}
                      path={route.path}
                      element={
                        <Layout
                          toggleTheme={toggle}
                          useDefaultTheme={useDefaultTheme}
                        >
                          {route.component ? (
                            <route.component />
                          ) : (
                            <DefaultComponent />
                          )}
                        </Layout>
                      }
                    />
                  )
                )}
              </Routes>
            </Fragment>
          </ThemeProvider>
        </AppContext.Provider>
      </ErrorBoundary>
    </>
  );
}

export default App;

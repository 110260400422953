import React, { useRef, useEffect, FC, useState, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../hooks/reduxHooks"; // Ensure this path matches your file structure
import { CardHeader, Button } from "@mui/material";
import Loading from "../ui/loadingIndicator";
import { login, signup } from "./authSlice"; // Adjust the import path as needed
import { AuthTokenState } from "./types"; // Import your types
import Toasted from "../ui/toast"; // Import the Toasted component, adjust the path as necessary
import {
  AuthCard,
  AuthCardContent,
  AuthLabel,
  AuthInput,
  AuthCardActions,
  AuthToggleButton,
} from "./styled/authForm";

interface AuthFormProps {}

const AuthForm: FC<AuthFormProps> = (): React.ReactElement => {
  const history = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoggedIn, isAuthLoading, error } = useAppSelector(
    (state): AuthTokenState => state.auth
  );

  const userEmailRef = useRef<HTMLInputElement>(null);
  const userPasswordRef = useRef<HTMLInputElement>(null);
  const [isLogin, setIsLogin] = useState(true);
  // State for managing toast visibility and message
  const [toast, setToast] = useState({ open: false, message: "", status: "" });

  useEffect(() => {
    if (isLoggedIn) {
      history("/dashboard"); // Navigate to dashboard on success
      setToast({
        open: true,
        message: "Authentication successful",
        status: "success",
      });
    } else if (error) {
      setToast({ open: true, message: error, status: "error" }); // Display error on failure
    }
  }, [isLoggedIn, error, history]);

  const logInSubmitHandler = async (event: FormEvent) => {
    event.preventDefault();
    const email = userEmailRef.current?.value;
    const password = userPasswordRef.current?.value;

    if (email && password) {
      if (isLogin) {
        dispatch(login({ email, secret: password }));
      } else {
        dispatch(signup({ email, secret: password }));
      }
    }
  };

  const switchAuthModeHandler = () => {
    setIsLogin(!isLogin);
    // Optionally reset toast state on auth mode switch
    setToast({ open: false, message: "", status: "" });
  };

  return (
    <>
      {toast.open && (
        <Toasted
          open={toast.open}
          message={toast.message}
          status={toast.status}
        />
      )}
      <AuthCard>
        <CardHeader title={isLogin ? "Login" : "Signup"} />
        <form onSubmit={logInSubmitHandler}>
          <AuthCardContent>
            <AuthLabel htmlFor="email">Your Email</AuthLabel>
            <AuthInput ref={userEmailRef} type="email" id="email" required />
          </AuthCardContent>
          <AuthCardContent>
            <AuthLabel htmlFor="password">Your Password</AuthLabel>
            <AuthInput
              ref={userPasswordRef}
              type="password"
              id="password"
              required
            />
          </AuthCardContent>
          <AuthCardActions>
            {!isAuthLoading && (
              <Button type="submit" variant="contained">
                {isLogin ? "Login" : "Signup"}
              </Button>
            )}
            {isAuthLoading && <Loading />}
            <AuthToggleButton onClick={switchAuthModeHandler}>
              {isLogin ? "Switch to Signup" : "Switch to Login"}
            </AuthToggleButton>
          </AuthCardActions>
        </form>
      </AuthCard>
    </>
  );
};

export default AuthForm;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../appStore";
import { Stream, FetchStreamsResponse } from "./types";


// Using createAsyncThunk to handle fetching streams
export const fetchStreams = createAsyncThunk<
  FetchStreamsResponse,
  undefined, // No need for a second parameter for additional argument
  { state: RootState }
>(
  "streams/fetchStreams", // Action type prefix
  async (_, { getState, rejectWithValue }) => {
    const { status } = getState().streams;

    if (status !== "idle" && status !== "failed") {
      // Early return if not in a fetchable state
      return rejectWithValue(
        "Fetch operation skipped: streams are either loading or already loaded."
      );
    }

    try {
      const response = await fetch("https://api.twitch.tv/helix/streams", {
        headers: {
          "Client-ID": "your-client-id",
          Authorization: "Bearer your-access-token",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      return { data: data.data }; // Assuming data.data holds the streams
    } catch (error: any) {
      return rejectWithValue(error.toString());
    }
  }
);

import React, { FC, ReactElement } from "react";
import { Helmet } from "react-helmet";
import StreamsComponent from "../components/twitch/TwitchStreams";
import PageTitle from "../components/ui/pageTitle";
import {
  APP_TITLE,
  PAGE_TITLE_DASHBOARD,
  SUBPAGE_TITLE,
} from "../helpers/constants";
import { DashboardRoot } from "./styled/dashboard";
import BasePage from "../components/ui/basePage";
const TwitchPage: FC = (): ReactElement => {
  return (
    <>
      <Helmet>
        <title>
          {PAGE_TITLE_DASHBOARD} | {APP_TITLE}
        </title>
      </Helmet>
      <DashboardRoot>
        <BasePage title="" navigation="Streaming">
          <StreamsComponent />
        </BasePage>
      </DashboardRoot>
    </>
  );
};

export default TwitchPage;

// In your styles file
import { styled, Theme } from "@mui/material/styles";

// Constants import
import {
  ERRORWIN_TOP,
  ERRORWIN_LEFT,
  ERRORWIN_WIDTH,
} from "../../../../helpers/constants";

export const ConfirmPopup = styled("div")(({ theme }: { theme: Theme }) => ({
  position: "fixed",
  top: ERRORWIN_TOP,
  left: ERRORWIN_LEFT, // Assuming ERRORWIN_LEFT is a measurement in pixels
  width: ERRORWIN_WIDTH,
  background: theme.palette.info.light,
  // Add any additional styling here
  zIndex: 1000, // Ensure it's above other content
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

// Import the styled function from Material-UI
import { styled } from "@mui/material/styles";
import Paper from '@mui/material/Paper';

import Divider from '@mui/material/Divider';
import {
    fontSizeL,
    CARD_WIDTH,
    fontWeightHeavy,
    fontSizeXL,
    PADDING_BOTTOM,
    APP_LOGO_PATH,
    MARGIN_BOTTOM,
    MEDIA_HEIGHT,
    spacingUnitlarge,
    APP_LOGO_WIDTH,
    FLEX_GROW,
    BORDER_STYLE_MAIN,
    MEDIA_HEIGHT_px,
    spacingUnitSmall,
} from "../../helpers/constants";



// Styled components
const Root = styled("div")(({ theme }) => ({
    flexGrow: FLEX_GROW,
    marginTop: theme.spacing(spacingUnitSmall), // Adjusted for Material-UI spacing
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
}));

const Navigation = styled("nav")(({ theme }) => ({
    fontSize: fontSizeL,
    fontWeight: fontWeightHeavy,
    color: theme.palette.primary.main, // Assuming you want to use the main color
    paddingBottom: PADDING_BOTTOM,
    display: "block",
}));

export const Paper_Content = styled(Paper)(({ theme }) => ({
  // Styles corresponding to globalStyles.paper
    margin: theme.spacing(3),
    padding: theme.spacing(spacingUnitlarge),
  // Add more styles as needed
}));

const BackgroundContainerImage = styled("div")(({ theme }) => ({
    flexGrow: 1,
    marginTop: theme.spacing(spacingUnitSmall),
    backgroundImage: `url(${APP_LOGO_PATH})`,
    backgroundSize: "cover",
    width: "100%",
    height: "300px",
    border: BORDER_STYLE_MAIN,
}));

const BackgroundContainer = styled("div")(({ theme }) => ({
    flexGrow: 1,
    marginTop: theme.spacing(spacingUnitSmall),
    backgroundSize: "cover",
    width: "100%",
    height: "100%",
    border: BORDER_STYLE_MAIN,
}));

const Title = styled("h1")(({ theme }) => ({
    fontSize: fontSizeXL,
    fontWeight: fontWeightHeavy,
    marginBottom: MARGIN_BOTTOM,
}));

export const ClearFix = styled("div")({
  // Styles corresponding to globalStyles.clear
    clear: "both",
  // Add any additional styles needed
});


const Clear = styled("div")(() => ({
    clear: "both",
}));

const Card = styled("div")(({ theme }) => ({
    maxWidth: CARD_WIDTH,
}));

const Media = styled("div")(({ theme }) => ({
  height: MEDIA_HEIGHT,
}));

const BackgroundMedia = styled("div")(({ theme }) => ({
  flexGrow: FLEX_GROW,
  marginTop: theme.spacing(spacingUnitSmall),
  backgroundSize: "cover",
  width: "100%",
  height: MEDIA_HEIGHT_px,
  border: BORDER_STYLE_MAIN,
}));

const Page = styled("div")(({ theme }) => ({
    padding: theme.spacing(spacingUnitSmall),
    backgroundColor: theme.palette.primary.light,
}));

const LogoContainer = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
}));

const Logo = styled("img")(({ theme }) => ({
  width: APP_LOGO_WIDTH,
  marginRight: theme.spacing(spacingUnitSmall),
}));

// Export the styled components
export {
    Root,
    Navigation,
    BackgroundContainerImage,
    BackgroundContainer,
    Title,
    Paper,
    Clear,
    Card,
    Media,
    BackgroundMedia,
    Page,
    LogoContainer,
    Logo,
};

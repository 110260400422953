// SettingsPage.tsx
import React, { FC, ReactElement } from "react";
import { Helmet } from "react-helmet";
import UserProfile from "../components/user/userProfile";
import PageTitle from "../components/ui/pageTitle";
import { APP_TITLE, PAGE_TITLE_SETTINGS } from "../helpers/constants";
import { SettingsRoot } from "./styled/settings"; // Ensure the path is correct
import BasePage from "../components/ui/basePage";
const SettingsPage: FC<{}> = (): ReactElement => {
  return (
    <>
      <Helmet>
        <title>
          {PAGE_TITLE_SETTINGS} | {APP_TITLE}
        </title>
      </Helmet>
      <SettingsRoot>
        <BasePage title={PAGE_TITLE_SETTINGS} navigation={PAGE_TITLE_SETTINGS}>
          <UserProfile />
        </BasePage>
      </SettingsRoot>
    </>
  );
};

export default SettingsPage;

import { styled } from "@mui/material/styles";
import {
  Sketch_HEIGHT,
  Sketch_WIDTH,
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
  DRAWER_WIDTH,
  PAGE_WIDTH,
} from "../../../../helpers/constants";
const PREFIX = "homePage";
export const classes = {
  content: `${PREFIX}-content`,
  container: `${PREFIX}-container`,
  paper: `${PREFIX}-paper`,
  fixedHeight: `${PREFIX}-fixedHeight`,
  svgBackgroundContainer: `${PREFIX}-svgBackgroundContainer`,
};

export const root = styled("div")(({ theme }) => ({
  [`&.${classes.content}`]: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  [`&.${classes.container}`]: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  [`&.${classes.paper}`]: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  [`&.${classes.fixedHeight}`]: {
    height: 440,
  },
  [`&.${classes.svgBackgroundContainer}`]: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/backgrounds/skynet.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    // Add other styling as needed
    width: "100%", // Example width
    height: "500px", // Example height
  },
}));

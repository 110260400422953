//This keeps the style outside of the main code
import { styled } from "@mui/material/styles";
import { DRAWER_WIDTH, FOOTER_HEIGHT } from "../../../../helpers/constants";
import { Theme } from "@mui/material/styles";

export const LayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  minHeight: `calc(100vh - ${FOOTER_HEIGHT}px)`,
}));

// Corrected usage of shouldForwardProp to exclude 'open' from being forwarded
export const MainContent  = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: theme.spacing(7) + 1,
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(9) + 1,
  },
}));

export const ToolbarPlaceholder = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

export const FooterRoot = styled("footer")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  background: theme.palette.background.paper,
  minHeight: FOOTER_HEIGHT,
}));

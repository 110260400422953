import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { DRAWER_WIDTH } from "../../../../helpers/constants";

export const TopAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<{ open: boolean }>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const TopToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const MenuButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "open",
})<{ open: boolean }>(({ open }) => ({
  marginRight: 36,
  ...(open && {
    display: "none",
  }),
}));

export const TitleContainer = styled(Typography)(({ theme }) => ({
  flex: 1,
  display: "flex",
  alignItems: "center",
}));

export const ThemeSwitchButton = styled(IconButton)(({ theme }) => ({
  // Optional: Add specific styles for the theme switch button here
}));

export const AuthButton = styled(IconButton)(({ theme }) => ({
  // Optional: Add specific styles for the authentication button here
}));

import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';


const LoadingIndicator = () => (
  <CircularProgress color="primary" />

);

export default LoadingIndicator;

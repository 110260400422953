// errorModalStyles.ts
import { styled } from "@mui/material/styles";
import {
  ERRORWIN_TOP,
  ERRORWIN_LEFT,
  ERRORWIN_WIDTH,
} from "../../../../helpers/constants";

export const ErrorModalWrapper = styled("div")(({ theme }) => ({
  position: "fixed",
  top: ERRORWIN_TOP,
  left: ERRORWIN_LEFT, // Assuming ERRORWIN_LEFT is in pixels
  transform: "translate(-50%, -50%)",
  width: ERRORWIN_WIDTH,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: theme.shadows[5],
  p: theme.spacing(4),
}));

import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { store } from "./appStore";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import App from "./App";

declare global {
  interface Window {}
}
// Assuming your HTML file has a div with id='root'
const container = document.getElementById("root");
// Create a root.
if (!container) throw new Error("Failed to find the root element");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

// Initial render: Render an element to the root.
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
serviceWorker.unregister();



//FireBase Auth Login Request
export interface ToastItemProps {
  open: boolean;
  message: string;
  status: string;
}

export interface ConfirmModalProps {
  open: boolean;
  title: string;
  message: string;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  onConfirm: React.MouseEventHandler<HTMLButtonElement>;
  onReject: React.MouseEventHandler<HTMLButtonElement>;
}
export interface ErrorModalProps {
  open: boolean;
  title: string;
  message: string;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
}
export enum ErrorBoundaryMode {
  Debug = "debug",
  Test = "test",
  UAT = "uat",
  Prod = "prod",
}

export interface UIState {
  isLoading: boolean;
  notification: ToastItemProps;
}
export interface TimerState {
  isLoading: boolean;
  notification: ToastItemProps;
}

export type Pokemon = { name: string; url: string };
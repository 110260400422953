import React from "react";
import Divider from "@mui/material/Divider";
import { Navigation, Title, ClearFix } from "../../theme/styles"; // Keep existing imports
import { BasePageRoot, StyledPaperContent } from "./styled/basePage"; // Adjust the import path

interface BasePageProps {
  title?: string;
  navigation?: string;
  children?: React.ReactNode;
}

const BasePage: React.FC<BasePageProps> = ({ title, navigation, children }) => {
  return (
    <BasePageRoot>
      <StyledPaperContent>
        <Title>{title}</Title>
        <Divider />
        {children}
        <ClearFix />
      </StyledPaperContent>
    </BasePageRoot>
  );
};

export default BasePage;

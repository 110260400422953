// footerStyles.ts
import { styled } from "@mui/material/styles";
import { FOOTER_HEIGHT } from "../../../../helpers/constants";
import Link from "@mui/material/Link";

export const FooterContent = styled('div')(({ theme }) => ({
  textTransform: "uppercase",
  // Additional styling for content can be added here
}));

export const FooterLink = styled(Link)(({ theme }) => ({
  textTransform: "uppercase",
  // Add any additional styling you want for the link
}));
// appMenu.tsx
import React, { FC, ReactElement, useState } from "react";
import {
  List,
  ListItemIcon,
  ListItemText,
  Collapse,
  Icon,
  Tooltip,
} from "@mui/material";
import DefaultIcon from "@mui/icons-material/AccountBox";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useLocation } from "react-router-dom";

import MenuItem from "./menuItem";
import { Loadroutes } from "../../config";
import RouteItem from "../../config/RouteItem.model";
import {
  MenuDivider,
  NestedMenuItem,
  SelectedMenuItem,
} from "./styled/appMenu";

const Menu: FC<{}> = (): ReactElement => {
  const [open, setOpen] = useState(false);
  const location: any = useLocation();
  let routes = Loadroutes();

  const handleClick = (): void => {
    setOpen(!open);
  };

  return (
    <List>
      {routes.map((route: RouteItem) => (
        <React.Fragment key={route.key}>
          {route.subRoutes ? (
            <>
              <SelectedMenuItem
                onClick={handleClick}
                isSelected={
                  !open &&
                  route.subRoutes.some(
                    (item) => item.path === location.pathname
                  )
                }
              >
                <ListItemIcon>
                  <Icon component={route.icon || DefaultIcon} />
                </ListItemIcon>
                <ListItemText primary={route.title} />
                {open ? <ExpandLess /> : <ExpandMore />}
              </SelectedMenuItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <NestedMenuItem>
                  {route.subRoutes.map((sRoute: RouteItem) => (
                    <MenuItem
                      key={sRoute.key}
                      title={sRoute.title}
                      icon={sRoute.icon}
                      tooltip={sRoute.tooltip}
                      path={sRoute.path}
                      enabled={sRoute.enabled}
                      component={sRoute.component}
                      subRoutes={sRoute.subRoutes}
                    />
                  ))}
                </NestedMenuItem>
              </Collapse>
            </>
          ) : (
            <MenuItem
              key={route.key}
              title={route.title}
              icon={route.icon}
              tooltip={route.tooltip}
              path={route.path}
              enabled={route.enabled}
              component={route.component}
              subRoutes={route.subRoutes}
            />
          )}
          {route.appendDivider && <MenuDivider />}
        </React.Fragment>
      ))}
    </List>
  );
};

export default Menu;

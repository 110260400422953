import React from 'react';

import Card from '@mui/material/Card';
import Container from "@mui/material/Container";
import clsx from 'clsx';

// define css-in-js
import { classes } from "./styled/webPage"

const StartingPageContent = () => {
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  return (
    <Container className={classes.paper}>
      <img
        src={process.env.PUBLIC_URL + "/backgrounds/gr02.png"}
        alt="Splash Image"
      />
    </Container>
  );
};

export default StartingPageContent;

// src/api/projectAPI.ts
import { IProjectInfo } from "./types"; // Adjust the path as needed
 
export const fetchList = async (
  serverPath: string
): Promise<IProjectInfo[]> => {
  try {
    console.log("fetchList");
    const response = await fetch(serverPath);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const projects: IProjectInfo[] = await response.json();
    return projects;
  } catch (error) {
    console.log("fetchList error");
    console.error("Error fetching projects:", error);
    throw error; // Rethrow the error to handle it in the calling function
  }
};

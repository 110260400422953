// UserProfile.tsx
import React from "react";
import ProfileForm from "./profileForm";
import { UserProfileCard } from "./styled/userProfile"; // Ensure the path is correct
import CardHeader from "@mui/material/CardHeader";
import { PAGE_WIDTH } from "../../helpers/constants";

const UserProfile = () => {
  const Title = "Profile Page";
  const subTitle = "Welcome Back!";

  return (
    <UserProfileCard title={Title}>
      {/* If you need to use CardHeader, it can be inserted here, or adjust as needed */}
      <ProfileForm />
    </UserProfileCard>
  );
};

export default UserProfile;

// AuthPageStyles.ts
import { styled } from "@mui/material/styles";

// Define a styled component for the root of the AuthPage
export const AuthPageRoot = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width:"100%"
  // Add other styling here as needed
}));

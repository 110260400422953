// Home.tsx
import React, { FC, ReactElement } from "react";
import { Helmet } from "react-helmet";
import HomeContent from "../components/pageContent/homeContent";
// Components
import PageTitle from "../components/ui/pageTitle";
// Constants
import { APP_TITLE, PAGE_TITLE_HOME } from "../helpers/constants";
// Styles
import { HomeRoot } from "./styled/home"; // Update the import path as necessary
import BasePage from "../components/ui/basePage";

const Home: FC<{}> = (): ReactElement => {
  return (
    <>
      <Helmet>
        <title>
          {PAGE_TITLE_HOME} | {APP_TITLE}
        </title>
      </Helmet>
      <HomeRoot>

        <PageTitle title={PAGE_TITLE_HOME} />
        <HomeContent />
      </HomeRoot>
    </>
  );
};

export default Home;

// menuItemStyles.ts
import { styled } from "@mui/material/styles";
import ListItemButton from "@mui/material/ListItemButton";

export const SelectedListItem = styled(ListItemButton)(({ theme }) => ({
  // ... styles for selected menu item
}));

export const NestedListItem = styled(ListItemButton)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

export const DisabledListItem = styled(ListItemButton)(({ theme }) => ({
  cursor: "not-allowed",
}));

// MenuItem.tsx
import React, { FC, ReactElement } from "react";
import {
  ListItemText,
  ListItemIcon,
  Icon,
  Tooltip,
  IconButton,
} from "@mui/material";
import DefaultIcon from "@mui/icons-material/FileCopy";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import RouteItem from "../../config/RouteItem.model";
import {
  SelectedListItem,
  NestedListItem,
  DisabledListItem,
} from "./styled/menuItem";

const MenuItem: FC<RouteItem> = (route: RouteItem): ReactElement => {
  const location = useLocation();

  // Determine the correct ListItem variant to use
  const getListItemVariant = () => {
    if (!route.enabled) return DisabledListItem;
    if (location.pathname === route.path) return SelectedListItem;
    return NestedListItem;
  };

  const ListItemVariant = getListItemVariant();

  return (
    <NavLink to={route.path}
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <Tooltip title={route.tooltip || ""} placement="right">
        <ListItemVariant disabled={!route.enabled}>
          <ListItemIcon>
            <IconButton size="small" disabled={!route.enabled}>
              <Icon component={route.icon || DefaultIcon} />
            </IconButton>
          </ListItemIcon>
          <ListItemText primary={route.title} />
        </ListItemVariant>
      </Tooltip>
    </NavLink>
  );
};

export default MenuItem;

import React, { FC, ReactElement } from "react";
import { Helmet } from "react-helmet";
import ProjectInfoList  from "../components/projects/projectsList";
import ImageCarousel from "../components/ui/ImageCarousel";
import PageTitle from "../components/ui/pageTitle";
import {
  APP_TITLE,
  PAGE_TITLE_DASHBOARD,
  SUBPAGE_TITLE,
} from "../helpers/constants";
import { DashboardRoot } from "./styled/dashboard";
import BasePage from "../components/ui/basePage";
const Dashboard: FC = (): ReactElement => {
  return (
    <>
      <Helmet>
        <title>
          {PAGE_TITLE_DASHBOARD} | {APP_TITLE}
        </title>
      </Helmet>
      <DashboardRoot>
        <BasePage title={SUBPAGE_TITLE} navigation="Projects">
          <ProjectInfoList />
        </BasePage>
        <ImageCarousel
          images={[
            process.env.PUBLIC_URL + "/users/gcaim.jpg",
            process.env.PUBLIC_URL + "/users/cameronm.jpg",
            process.env.PUBLIC_URL + "/users/chazm.jpg",
            process.env.PUBLIC_URL + "/users/chadm.png",
            process.env.PUBLIC_URL + "/users/ericj.png",
            process.env.PUBLIC_URL + "/users/jasonm.png",
          ]}
        ></ImageCarousel>
      </DashboardRoot>
    </>
  );
};

export default Dashboard;

// DashboardStyles.ts
import { styled } from "@mui/material/styles";
import {
  // Assuming these constants are used for further customizations
  Sketch_HEIGHT,
  Sketch_WIDTH,
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
  DRAWER_WIDTH,
  PAGE_WIDTH,
} from "../../../helpers/constants";

// Styled component for the Dashboard's root div
export const DashboardRoot = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  // Add other styling here as needed, possibly using the constants for responsiveness or theming
}));

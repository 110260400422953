import { styled } from "@mui/material/styles";

export const BasePageRoot = styled("div")(({ theme }) => ({
  marginLeft: theme.spacing(25), // Adjust the left margin as needed
  // You can add more styling here to match your design
}));

export const StyledPaperContent = styled("div")(({ theme }) => ({
  padding: theme.spacing(0),
  margin: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  width: "100%",
  // Additional styles for the content area
}));

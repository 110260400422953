import React from "react";
import { ToastRoot, ToastError, ToastSuccess } from "./styled/toast"; // Adjust the import path as necessary
import { ToastItemProps } from "./types";

//Use React.memo to create a tuple from the Props property
const Toasted: React.FC<ToastItemProps> = React.memo(
  ({ open,message,status}) => {
    let ToastComponent = ToastRoot; // Default component

    if (status === "error") {
      ToastComponent = ToastError;
    } else if (status === "success") {
      ToastComponent = ToastSuccess;
    }
    // Assuming you want to handle 'loading' and 'idle' differently, you could add more conditions here

    return (
      <ToastComponent>
        <h2>{message}</h2>
      </ToastComponent>
    );
  }
);

export default Toasted;

import { styled } from "@mui/material/styles";

// Assuming constants are imported correctly
import {
  Sketch_HEIGHT,
  Sketch_WIDTH,
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
  DRAWER_WIDTH,
  PAGE_WIDTH,
} from "../../../../helpers/constants";

// Styled components for each variant
export const ToastRoot = styled("div")(({ theme }) => ({
  margin: `3rem auto`,
  width: `100%`,
  maxWidth: `25rem`,
  borderRadius: `6px`,
  backgroundColor: theme.palette.secondary.main,
  boxShadow: `0 0 3px ${theme.palette.primary.main}, 0 0 9px ${theme.palette.primary.main}, 0 0 11px ${theme.palette.primary.main}, 0 0 30px ${theme.palette.primary.main}`,
  padding: `1rem`,
  textAlign: `left`,
}));

export const ToastError = styled(ToastRoot)({
  display: `block`,
  color: `red`, // Use theme.palette.error.main if you want to tie this to your theme's error color
  fontWeight: `bold`,
  marginBottom: "0.5rem",
});

export const ToastSuccess = styled(ToastRoot)(({ theme }) => ({
  marginTop: `1.5rem`,
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,
  color: theme.palette.success.main, // Assuming you want to change the text color to success color
}));

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../appStore"; // Adjust the import path as needed
import {
  AuthLogInReq,
  AuthTokenState,
  FB_AuthLoginResponse,
  FB_AuthSignUpResponse,
} from "./types";
import * as authApi from "./authAPI";

// Initial state
const initialState: AuthTokenState = {
  token: "",
  isLoggedIn: false,
  remainingTime: 0,
  isAuthLoading: false,
  provider: "Firebase",
  error:"",
  user: {
    localid: "",
    email: "",
    photo: "",
  },
};

export const login = createAsyncThunk<
  FB_AuthLoginResponse,
  AuthLogInReq,
  { state: RootState }
>("auth/login", async (credentials, thunkAPI) => {
  try {
    const response = await authApi.signInWithPassword(credentials);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue("Login failed");
  }
});

export const signup = createAsyncThunk<
  FB_AuthSignUpResponse,
  AuthLogInReq,
  { state: RootState }
>("auth/signup", async (credentials, thunkAPI) => {
  try {
    const response = await authApi.signUp(credentials);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue("Signup failed");
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Define logout action within the reducers
    logout(state) {
      state.token = "";
      state.isLoggedIn = false;
      state.user = { localid: "", email: "", photo: "" };
      state.isAuthLoading = false;
      state.error="";
      // Clear any relevant localStorage or session tokens here if necessary
      localStorage.removeItem("token");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isAuthLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isAuthLoading = false;
        state.token = action.payload.idToken;
        state.isLoggedIn = true;
        state.user = {
          localid: action.payload.localId,
          email: action.payload.email,
          photo: "",
        }; // Assuming photo is managed elsewhere
      })
      .addCase(login.rejected, (state, action) => {
        state.isAuthLoading = false;
        state.isLoggedIn = false;
        state.error = action.error.message || "Login failed";
      })
      .addCase(signup.pending, (state) => {
        state.isAuthLoading = true;
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.isAuthLoading = false;
        // Handle signup success logic
      })
      .addCase(signup.rejected, (state, action) => {
        state.isAuthLoading = false;
        state.isLoggedIn = false;
        state.error = action.error.message || "Login failed";
        // Handle signup failure logic
      });
  },
});

export const authActions = authSlice.actions;
export const selectAuth = (state: RootState) => state.auth;
export default authSlice.reducer;

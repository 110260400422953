import React, { Component, ErrorInfo, ReactNode } from "react";

import { ErrorBoundaryMode } from "../ui/types";

interface Props {
  children: ReactNode;
  mode: ErrorBoundaryMode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Different error logging based on the mode
    if (this.props.mode === ErrorBoundaryMode.Debug) {
      console.log("Caught in Debug Mode:", error, errorInfo);
    } else {
      // logErrorToMyService(error, errorInfo); // For other modes
    }
  }

  render() {
    if (this.state.hasError) {
      // Optionally render different UI based on mode
      if (this.props.mode === ErrorBoundaryMode.Debug) {
        return (
          <div>
            <h2>Debug Mode: Something went wrong.</h2>
            <details style={{ whiteSpace: "pre-wrap" }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo?.componentStack}
            </details>
          </div>
        );
      } else {
        // Fallback UI for other modes
        return <h2>Something went wrong. Please try again later.</h2>;
      }
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

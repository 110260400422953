// icons
import React, { useContext } from "react";
import HomeIcon from "@mui/icons-material/Home";
import LoginIcon from "@mui/icons-material/AddToHomeScreenOutlined";
import DashboardIcon from "@mui/icons-material/BarChartOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import WalletIcon from "@mui/icons-material/AccountBalanceWallet";
import BankIcon from "@mui/icons-material/AccountTreeOutlined";
import CartIcon from "@mui/icons-material/ShoppingBasketOutlined";
import stellarIcon from "@mui/icons-material/MonetizationOnOutlined";
import WifiIcon from "@mui/icons-material/NetworkWifiRounded";
import UserIcon from "@mui/icons-material/VerifiedUserRounded";

//Authorization
import { useAppSelector } from "../hooks/reduxHooks";

// components
import Home from "../pages/home";
import Dashboard from "../pages/dashboard";
import TwitchPage  from "../pages/twitchPage";
//import BankPage from "../pages/accounts/bankAccount";
import SettingsPage from "../pages/settingsPage";
import AuthPage from "../pages/authPage";

// interface
import RouteItem from "./RouteItem.model";
import { selectAuth } from "../components/auth/authSlice";
import { selectStream } from "../components/stream/streamSlice"

// define app routes
export const Loadroutes = () => {
  const authState = useAppSelector(selectAuth);
  const twitchState = useAppSelector(selectStream);

  const isLoggedIn = authState.isLoggedIn;
  const isStreaming = twitchState.items.length > 0;
  const _tokenTime = authState.remainingTime;
  let routes: Array<RouteItem> = [];
  if (isLoggedIn !== undefined) {
    routes = [
      {
        key: "router-home",
        title: "Home",
        tooltip: "Home",
        path: "/",
        enabled: true,
        component: Home,
        icon: HomeIcon,
        appendDivider: true,
      },
      {
        key: "router-streamin",
        title: "Streaming",
        tooltip: "Watch Our Streams",
        path: "/streaming",
        enabled: isStreaming,
        component: TwitchPage,
        icon: WifiIcon,
      },
      {
        key: "router-auth",
        title: "User",
        tooltip: "User Identity",
        path: "/auth",
        enabled: !isLoggedIn,
        component: AuthPage,
        icon: UserIcon,
      },
      {
        key: "router-mostviewed",
        title: "Most Viewed",
        tooltip: "Most Viewed Streams",
        path: "/mview",
        enabled: !isLoggedIn,
        component: Dashboard,
        icon: DashboardIcon,
      },
      {
        key: "router-settings",
        title: "Settings",
        tooltip: "Settings",
        path: "/settings",
        enabled: !isLoggedIn,
        component: SettingsPage,
        icon: SettingsIcon,
      },
    ];
  }
  return routes;
};

// authFormStyles.ts
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

export const AuthCard = styled(Card)(({ theme }) => ({

}));

export const AuthCardContent = styled(CardContent)(({ theme }) => ({

}));

export const AuthLabel = styled("label")(({ theme }) => ({
display: `grid`,
gridTemplateColumns: `repeat(2, 1fr)`,
color: theme.palette.text.secondary,
fontWeight: `bold`,
marginBottom: "0.5rem",
})); 
export const AuthInput = styled("input")(({ theme }) => ({
borderRadius: `4px`,
width: `100%`,
padding: theme.spacing(2),
}));

export const AuthCardActions = styled(CardActions)(({ theme }) => ({
marginTop: `1.5rem`,
display: `flex`,
flexDirection: `column`,
alignItems: `center`,
}));

export const AuthToggleButton = styled("button")(({ theme }) => ({
marginTop: `1rem`,
backgroundColor: `transparent`,
color: theme.palette.primary.contrastText,
border: `none`,
padding: theme.spacing(1),
}));

import { createSlice } from "@reduxjs/toolkit";
import { fetchStreams } from "./twitchThunk";
import { StreamState } from "./types";
import { RootState } from "../../appStore";

const initialState: StreamState = {
  items: [],
  status: "idle",
  error: null,
};

const streamsSlice = createSlice({
  name: "streams",
  initialState,
  reducers: {
    // Add any sync reducers if needed
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchStreams.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchStreams.fulfilled, (state, action) => {
        // Ensure that the payload is of the correct type
        state.status = "succeeded";
        state.items = action.payload.data; // Assuming the payload directly contains the Stream[]
      })
      .addCase(fetchStreams.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? "Failed to fetch streams";
      });
  },
});

//This does not need to be here but nice to have
export const selectStream = (state: RootState) => state.streams;
export const selectActions = streamsSlice.actions;
export default streamsSlice.reducer;

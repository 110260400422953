import React from "react";
import { ConfirmPopup } from "./styled/ConfirmModal"; // Adjust the import path as necessary
import { ConfirmModalProps } from "./types";

const ConfirmModal: React.FC<ConfirmModalProps> = React.memo(
  ({ title, message, onConfirm, onReject,open }) => {
      if (!open) return null;
    return (
      <React.Fragment>
        <div className="backdrop" />
        <ConfirmPopup>
          <h2>Confirm {title}</h2>
          <p>{message}</p>
          <div className="error-modal__actions">
            <button type="button" onClick={onConfirm}>
              YES
            </button>
            <button type="button" onClick={onReject}>
              NO
            </button>
          </div>
        </ConfirmPopup>
      </React.Fragment>
    );
  }
);

export default ConfirmModal;

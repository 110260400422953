import React from "react";
import PropTypes from "prop-types";
import {
  createTheme,
  withStyles,
  Theme,
  ThemeOptions,
} from "@mui/material/styles";
import { red } from "@mui/material/colors";
import ThemeColors from "./customColors";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import cryoRIOLightPrimaryColor  from "./fieldProColors";



const styles = {
    root: {
    display: "flex",
    },
};
//Use our theme class to get our saved colors
const themeColors = new ThemeColors();
//Lght Theme
const lightPrimary = themeColors.getLightColors().primary;
const lightSecondary = themeColors.getLightColors().secondary;
const lightAccent = themeColors.getLightColors().accent;

declare module "@mui/material/styles" {
  interface PaletteOptions {
    mode?: "light" | "dark"; // Palette type can be 'light' or 'dark'
  }
}

// define light theme colors
export const lightTheme: Theme = createTheme({
  palette: {
    mode: 'light',
    //dark blue
    primary: {
      main: lightPrimary[900],
    },
    //light blue
    secondary: {
      main: lightSecondary[500],
    },
    info: {
      main: red[200],
    },
    warning: {
      main: red[500],
    },
  },
  typography: {
    fontFamily: "Leelawadee UI",
    fontSize: 18,
    fontWeightMedium: 500,
    body1: {
      fontFamily: "Leelawadee UI",
      fontWeight: 500,
    },
    body2: {
      fontFamily: "Leelawadee UI",
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 35,
    },
    button: {
      fontStyle: "italic",
      fontFamily: "Airel",
    },
  },
});


//Define Dark Theme Colors
const darkPrimary = themeColors.getDarkColors().primary;
const darkSecondary = themeColors.getLightColors().secondary;
const darkAccent = themeColors.getLightColors().accent;
// define dark theme colors
export const darkTheme: Theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: darkPrimary[500],
    },
    secondary: {
      main: darkSecondary[400],
    },
    info: {
      main: darkAccent[700],
    },
    warning: {
      main: red[400],
    },
  },
  typography: {
    fontFamily: "Leelawadee UI",
    fontSize: 18,
    fontWeightMedium: 500,
    body1: {
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 35,
    },
    button: {
      fontStyle: "italic",
    },
  },

});
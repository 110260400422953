// styled/ProfileFormStyles.ts
import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import CardActions  from "@mui/material/CardActions";
import Button from "@mui/material/Button";

export const ProfileFormRoot = styled("form")(({ theme }) => ({
  margin: `3rem auto`,
  width: `95%`,
  maxWidth: `25rem`,
  borderRadius: `6px`,
  padding: `1rem`,
  textAlign: `left`,
}));

export const ProfileFormControl = styled(CardContent)(({ theme }) => ({
  marginBottom: `0.5rem`,
}));

export const ProfileFormLabel = styled("label")(({ theme }) => ({
  display: `block`,
  color: theme.palette.text.secondary,
  fontWeight: `bold`,
  marginBottom: "0.5rem",
}));

export const ProfileFormInput = styled("input")(({ theme }) => ({
  borderRadius: `4px`,
  width: `100%`,
  padding: theme.spacing(2),
  textAlign: `left`,
}));

export const ProfileFormActions = styled(CardActions)(({ theme }) => ({
  marginTop: `1.5rem`,
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,
}));

export const ProfileFormToggle = styled(Button)(({ theme }) => ({
  marginTop: `1rem`,
  backgroundColor: `transparent`,
  color: theme.palette.primary.contrastText,
  border: `none`,
  padding: theme.spacing(1),
}));

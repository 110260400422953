// AuthPage.tsx
import React, { FC, ReactElement } from "react";
import { Helmet } from "react-helmet";
import AuthForm from "../components/auth/authForm";
import { APP_TITLE, PAGE_TITLE_AUTH } from "../helpers/constants";
import PageTitle from "../components/ui/pageTitle";
import { AuthPageRoot } from "./styled/authPage"; // Ensure this path matches your file structure
import BasePage from "../components/ui/basePage";
const AuthPage: FC<{}> = (): ReactElement => {

  return (
    <>
      <Helmet>
        <title>
          {PAGE_TITLE_AUTH} | {APP_TITLE}
        </title>
      </Helmet>
      <AuthPageRoot>
        <BasePage title="Log In" navigation="">
          <AuthForm />
        </BasePage>
      </AuthPageRoot>
    </>
  );
};

export default AuthPage;

import React, { FC, ReactNode, useReducer } from "react";
import { CssBaseline } from "@mui/material";
import { useNavigate } from "react-router-dom";
// components
import Header from "./header";
import NavigationSideBar from "./navigationSideBar";
import Footer from "./footer";
//Move out to button
import { authActions } from "../../components/auth/authSlice";
// constants
import { useAppDispatch } from "../../hooks/reduxHooks";
// define css-in-js
import {
  LayoutRoot,
  MainContent,
  ToolbarPlaceholder,
  FooterRoot,
} from "./styled/layout";

// define interface to represent component props
interface Props {
  toggleTheme: () => void;
  useDefaultTheme: boolean;
  children: ReactNode;
}

// functional component
const Layout: FC<Props> = ({ toggleTheme, useDefaultTheme, children }) => {

  const [open, toggle] = useReducer((open) => !open, true);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  //Here are the different providers Handels this does the dispatching
  const connectFirebaseHandler = async () => {
    try {
      //route to user page
      //dispatch(authActions.setFBLoginData());
      //navigate("/auth");
    } catch (ex) {
      console.log(ex);
    }
  };


  const logoutHandler = () => {
    dispatch(authActions.logout());
    navigate("/");
  };

  return (
    <LayoutRoot>
      <CssBaseline />
      <Header
        open={open}
        handleMenuOpen={toggle}
        handleSignIn={connectFirebaseHandler}
        handleSignUp={connectFirebaseHandler}
        handleDisconnect={logoutHandler}
        toggleTheme={toggleTheme}
        useDefaultTheme={useDefaultTheme}
      />
      <NavigationSideBar open={open} handleMenuClose={toggle} />
      <MainContent>
        <ToolbarPlaceholder />
        {children}
      </MainContent>
      <FooterRoot>
        <Footer />
      </FooterRoot>
    </LayoutRoot>
  );
};

export default Layout;

import React, { FC, ReactElement } from "react";

// constants
import { FOOTER_TEXT } from "../../helpers/constants";

// define css-in-js
import { FooterContent, FooterLink } from "./styled/footer";

// functional component
const Footer: FC<{}> = (): ReactElement => {
  return (
    <FooterContent>
      <FooterLink
        href={`${process.env.REACT_APP_API_URL}`}
        target="_blank"
        rel="noreferrer"
      >
        {FOOTER_TEXT}
      </FooterLink>
    </FooterContent>
  );
};

export default Footer;
